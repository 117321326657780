import React from "react"
import PropTypes from "prop-types"

export const Checkbox = ({ name, label, onChange, value }) => (
  <div className="form-element-full-width checkbox">
    <input
      type="checkbox"
      name={name}
      id={name}
      onChange={onChange}
      defaultChecked={value}
    />
    <span className="checkbox-checkmark"></span>
    <label className="checkbox-label" htmlFor={name}>
      {label}
    </label>
  </div>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}
