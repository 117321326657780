export const formatPaymentType = (paymentDetails) => {
  if (paymentDetails.code === "up-front") {
    return "Up-front"
  } else if (paymentDetails.code === "50-percent-upfront") {
    return "50% up-front"
  } else if (paymentDetails.code === "finance") {
    if (paymentDetails.finance && paymentDetails.finance.months) {
      return `Finance (${paymentDetails.finance.months} months)`
    } else {
      return "Finance"
    }
  } else {
    return "No payment found"
  }
}
