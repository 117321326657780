import React from "react"
import PropTypes from "prop-types"

export { TableFilters, TableFiltersButton } from "./TableFilters"

export const Table = ({ children }) => (
  <table className="table">{children}</table>
)

export const TableBodyRow = ({ children, ...props }) => (
  <tr className="table-row" {...props}>
    {children}
  </tr>
)

export const TableHeaderRow = ({ children }) => (
  <tr className="table-header-row">{children}</tr>
)

export const TableBodyCell = ({ children, type, initialView }) => (
  <td
    className={`table-cell${initialView && ` ${initialView}`}${
      type ? ` table-cell-${type}` : ""
    }`}
  >
    {children}
  </td>
)

TableBodyCell.defaultProps = {
  initialView: "",
}

TableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["index", "bold", "boolean"]),
  initialView: PropTypes.oneOf([
    "mobile-large",
    "tablet",
    "desktop",
    "desktop-large",
    "",
  ]),
}

export const TableHeaderCell = ({ children, initialView }) => (
  <td className={`table-header-cell${initialView && ` ${initialView}`}`}>
    {children}
  </td>
)

TableHeaderCell.defaultProps = {
  initialView: "",
}

TableHeaderCell.propTypes = {
  initialView: PropTypes.oneOf([
    "mobile-large",
    "tablet",
    "desktop",
    "desktop-large",
    "",
  ]),
}
