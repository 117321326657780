import Moment from "moment"

export const formatChartData = (orders, baseChartData) => {
  const data = baseChartData
  orders.months.map((month) => {
    // new Date(year, month, day, hours, minutes, seconds, milliseconds)
    const monthString = Moment(new Date(0, month, 0, 0, 0, 0, 0)).format("MMM")
    return data.labels.push(monthString)
  })

  orders.allOrders.data.map((monthData) =>
    data.datasets[0].data.push(monthData)
  )
  orders.upfront.data.map((monthData) => data.datasets[1].data.push(monthData))
  orders.finance.data.map((monthData) => data.datasets[2].data.push(monthData))

  orders.halfUpfront.data.map((monthData) =>
    data.datasets[3].data.push(monthData)
  )
  return data
}
