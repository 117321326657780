import React from "react"
import { useSnackbar } from "react-simple-snackbar"
import { ReactSVG } from "react-svg"
import { Link, useLocation } from "@reach/router"

import { useAuth } from "../../hooks"
import { SnackBarOptions } from "../../components"

import HARSLogoNoIcons from "../../images/Home-Assist-Logo-No-Icons.svg"

export const Layout = ({ children }) => {
  const auth = useAuth()
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const location = useLocation()

  const signOut = () => {
    auth.signOut(() => openSnackbar("Could not log out, please try again"))
  }

  return (
    <>
      <header className="header">
        <section className="header-layout">
          <Link to="/">
            <ReactSVG src={HARSLogoNoIcons} className="header-logo" />
          </Link>
          <button className="header-button" onClick={() => signOut()}>
            Log out
          </button>
        </section>
      </header>
      <div className="layout">
        <nav className="nav">
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <span className="icon-grid nav-item-icon"></span>
                <span className="nav-item-text">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/orders"
                className={
                  location.pathname.includes("/orders") ? "active" : ""
                }
              >
                <span className="icon-tool nav-item-icon"></span>
                <span className="nav-item-text">Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/quotes"
                className={
                  location.pathname.includes("/quotes") ? "active" : ""
                }
              >
                <span className="icon-quote nav-item-icon"></span>
                <span className="nav-item-text">Quotes</span>
              </Link>
            </li>
          </ul>
        </nav>
        <main className="layout-screen">{children}</main>
      </div>
    </>
  )
}
