import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import { useNavigate } from "@reach/router"
import Moment from "moment"

import {
  BoilerSituation,
  SnackBarOptions,
  Table,
  TableBodyCell,
  TableBodyRow,
  TableFilters,
  TableFiltersButton,
  TableHeaderCell,
  TableHeaderRow,
  Loader,
  LoaderContainer,
} from "../../components"
import { formatPaymentType } from "../../utilities"

export const Orders = () => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [filters, setFilters] = React.useState({})
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })

  const [orders, setOrders] = React.useState({
    orders: [],
    amount: 0,
  })

  const navigate = useNavigate()

  const getOrders = async () => {
    setLoader({
      loading: true,
      error: false,
    })

    try {
      const orders = await axios({
        method: "post",
        url: "/orders",
        data: {
          filters,
        },
      })
      setOrders(orders.data)
    } catch (error) {
      console.log(error.response)
      setLoader((loader) => ({
        ...loader,
        error: true,
      }))
      if (error.response.data) {
        openSnackbar("Your account is not authorised")
      } else {
        openSnackbar("Could not get orders, refresh to try again")
      }
    }

    setLoader((loader) => ({
      ...loader,
      loading: false,
    }))
  }

  React.useEffect(() => {
    getOrders()
    // eslint-disable-next-line
  }, [filters])

  const navigateToOrder = (orderId) => {
    navigate(`/orders/${orderId}`)
  }

  return (
    <>
      <h1 className="heading-large">
        Orders{" "}
        {orders && orders.orders.length > 0 && `(${orders.orders.length})`}
      </h1>
      <TableFilters>
        <TableFiltersButton
          active={filters.installed === undefined}
          onClick={() => setFilters({})}
        >
          All
        </TableFiltersButton>
        <TableFiltersButton
          active={filters.installed === false}
          onClick={() =>
            setFilters((filters) => ({ ...filters, installed: false }))
          }
        >
          Not installed
        </TableFiltersButton>
        <TableFiltersButton
          active={filters.installed}
          onClick={() =>
            setFilters((filters) => ({ ...filters, installed: true }))
          }
        >
          Installed
        </TableFiltersButton>
      </TableFilters>
      <LoaderContainer type="list-view">
        {loader.loading || loader.error ? (
          <Loader
            text="Getting orders"
            error={loader.error}
            errorText="Could not get orders, refresh to try again"
          />
        ) : (
          orders &&
          orders.orders.length > 0 && (
            <Table>
              <thead>
                <TableHeaderRow>
                  <TableHeaderCell>Order</TableHeaderCell>
                  <TableHeaderCell initialView="tablet">
                    Situation
                  </TableHeaderCell>
                  <TableHeaderCell initialView="mobile-large">
                    Customer
                  </TableHeaderCell>
                  <TableHeaderCell>Install date</TableHeaderCell>
                  <TableHeaderCell initialView="desktop-large">
                    Boiler
                  </TableHeaderCell>
                  <TableHeaderCell initialView="tablet">
                    Payment
                  </TableHeaderCell>
                  <TableHeaderCell>Installed</TableHeaderCell>
                </TableHeaderRow>
              </thead>
              <tbody>
                {orders.orders.map((order) => (
                  <TableBodyRow
                    tabIndex={0}
                    key={order.id}
                    onClick={() => navigateToOrder(order.id)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && navigateToOrder(order.id)
                    }
                  >
                    <TableBodyCell type="index">
                      #{order.orderNumber}
                    </TableBodyCell>
                    <TableBodyCell initialView="tablet">
                      <BoilerSituation
                        situation={order.quote.boilerSituation}
                      />
                    </TableBodyCell>
                    <TableBodyCell type="bold" initialView="mobile-large">
                      {order.userDetails &&
                        `${order.userDetails.firstName} ${order.userDetails.lastName}`}
                    </TableBodyCell>
                    <TableBodyCell>
                      {Moment(order.installDate).format("DD/MM/YYYY")}
                    </TableBodyCell>
                    <TableBodyCell initialView="desktop-large">
                      {order.boiler && order.boiler.name}
                    </TableBodyCell>
                    <TableBodyCell initialView="tablet">
                      {order.paymentDetails &&
                        formatPaymentType(order.paymentDetails)}
                    </TableBodyCell>
                    <TableBodyCell type="boolean">
                      {order.installed ? (
                        <span className="icon-tick-circle" />
                      ) : (
                        <span className="icon-cross-circle" />
                      )}
                    </TableBodyCell>
                  </TableBodyRow>
                ))}
              </tbody>
            </Table>
          )
        )}
      </LoaderContainer>
    </>
  )
}
