import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import { defaultRequiredText } from "../../constants"
import {
  ButtonWithLoader,
  CardSection,
  DataDisplay,
  Form,
  Input,
  FormSection,
  SnackBarOptions,
} from ".."

import { LineItem } from "./LineItem"

export const LineItems = ({ setQuote, quoteId, items }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const addLineItem = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios({
        method: "post",
        url: "/quote/add-line-item",
        data: {
          quoteId: quoteId,
          description: values.description,
          price: values.price,
        },
      })
      setQuote(response.data)
      resetForm({})
      openSnackbar("Line item saved")
    } catch (error) {
      console.log(error, error.response)
      if (error.response.data) {
        openSnackbar("Your account is not authorised")
      } else {
        openSnackbar("Could not save line item, please try again")
      }
    }
    setSubmitting(false)
  }
  return (
    <CardSection desktopColumns={1}>
      <DataDisplay label="Custom line items">
        Additional items added to this quote if the customer places an order.
        Please include VAT in prices.
      </DataDisplay>
      {items && items.length > 0 && (
        <ul className="line-items">
          {items.map((item) => (
            <LineItem
              key={item.id}
              quoteId={quoteId}
              setQuote={setQuote}
              id={item.id}
              description={item.description}
              price={item.price}
            />
          ))}
        </ul>
      )}
      <Formik
        initialValues={{
          description: "",
          price: 0,
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required(defaultRequiredText),
          price: Yup.number().required(defaultRequiredText),
        })}
        onSubmit={addLineItem}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormSection>
              <Input name="description" label="Line item" />
              <Input name="price" label="Price (£)" type="number" />
            </FormSection>
            <ButtonWithLoader type="submit" disabled={isSubmitting}>
              Add row
            </ButtonWithLoader>
          </Form>
        )}
      </Formik>
    </CardSection>
  )
}

LineItems.propTypes = {
  items: PropTypes.array.isRequired,
  quoteId: PropTypes.string.isRequired,
  setQuote: PropTypes.func.isRequired,
}
