import React from "react"
import firebase from "firebase/app"
import "firebase/auth"
import axios from "axios"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOIMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

firebase.initializeApp(firebaseConfig)

const AuthContext = React.createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

function useProvideAuth() {
  const [user, setUser] = React.useState(null)

  const signIn = async (email, password, onError) => {
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
      await setBearerToken(response.user, setUser)
    } catch (error) {
      console.log(error)
      onError()
    }
  }

  const signOut = async (onError) => {
    try {
      await firebase.auth().signOut()
      setUser(false)
    } catch (error) {
      onError()
    }
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setBearerToken(user, setUser)
      } else {
        setUser(false)
      }
    })
    return () => unsubscribe()
  }, [])

  return {
    user,
    signIn,
    signOut,
  }
}

const setBearerToken = async (firebaseUser, setAppUser) => {
  const token = await firebaseUser.getIdToken()
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
  // Must only set user and active app after Bearer token is set
  setAppUser(firebaseUser)
}
