import React from "react"
import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"

import LoaderSVG from "../../images/loader.svg"

export const LoaderContainer = ({ children, type }) => (
  <section className={`loader-container ${type}`}>{children}</section>
)

LoaderContainer.defaultProps = {
  type: "full-screen",
}

LoaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["full-screen", "list-view", "single-view"]),
}

export const Loader = ({ error, errorText, text }) => (
  <div className="loader">
    {!error ? (
      <div className="loader-layout">
        <ReactSVG src={LoaderSVG} />
        <h2 className="heading-medium">{text}</h2>
      </div>
    ) : (
      <div className="loader-layout">
        <span className="icon-warning"></span>
        <h2 className="heading-medium">{errorText}</h2>
      </div>
    )}
  </div>
)

Loader.defaultProps = {
  errorText: "",
}

Loader.propTypes = {
  error: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  text: PropTypes.string.isRequired,
}
