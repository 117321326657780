import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import { IconButton, SnackBarOptions } from "../.."

export const LineItem = ({ id, quoteId, setQuote, description, price }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const removeLineItem = async () => {
    try {
      const response = await axios({
        method: "post",
        url: "/quote/remove-line-item",
        data: {
          quoteId: quoteId,
          lineItemId: id,
        },
      })
      setQuote(response.data)
      openSnackbar("Line item removed")
    } catch (error) {
      if (error.response.data) {
        openSnackbar("Your account is not authorised")
      } else {
        openSnackbar("Could not remove line item, please try again")
      }
    }
  }

  const priceFormatted = (price) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return formatter.format(price)
  }

  return (
    <li className="line-item">
      <div className="line-item-contents">
        <span className="line-item-contents-text">{description}</span>
        <span className="line-item-contents-text line-item-contents-price">
          {priceFormatted(price)}
        </span>
      </div>
      <div>
        <IconButton func={removeLineItem} icon="bin" name="Remove row" />
      </div>
    </li>
  )
}

LineItem.propTypes = {
  id: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
}
