import React from "react"
import PropTypes from "prop-types"

export { BoilerSituation } from "./BoilerSituation"

export const Tag = ({ children, type }) => (
  <span
    className={
      type === "alert"
        ? "tag tag-alert"
        : type === "warning"
        ? "tag tag-warning"
        : "tag"
    }
  >
    {children}
  </span>
)

Tag.defaultProps = {
  type: "normal",
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["normal", "warning", "alert"]),
}
