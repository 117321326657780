import React from "react"
import PropTypes from "prop-types"

export const ButtonWithIconLink = ({
  children,
  external,
  href,
  icon,
  iconSide,
  type,
}) => {
  if (external) {
    return (
      <a
        className={`button-${type} button-with-icon icon-${iconSide}`}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <span className={`button-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </a>
    )
  } else {
    return (
      <a
        className={`button-${type} button-with-icon icon-${iconSide}`}
        href={href}
      >
        <span className={`button-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </a>
    )
  }
}

ButtonWithIconLink.defaultProps = {
  external: false,
  icon: "arrow",
  iconSide: "left",
  type: "primary",
}

ButtonWithIconLink.propTypes = {
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconSide: PropTypes.oneOf(["left", "right"]),
  type: PropTypes.oneOf(["primary", "secondary"]),
}
