import React from "react"
import PropTypes from "prop-types"

export const FormSection = ({ children, columns }) => (
  <section className={`form-section form-section-${columns}`}>
    {children}
  </section>
)

FormSection.defaultProps = {
  columns: 2,
}

FormSection.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.node.isRequired,
}
