import React from "react"
import PropTypes from "prop-types"
import Moment from "moment"

export const Note = ({ note, user, postDate }) => (
  <article className="note">
    <header className="note-header">
      <span className="note-header-user">{user}</span>
      <span className="note-header-posted">
        {Moment(postDate).format("DD/MM/YYYY LT")}
      </span>
    </header>
    <div className="note-body">{note}</div>
  </article>
)

Note.propTypes = {
  note: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  postDate: PropTypes.string.isRequired,
}

export const NoteContainer = ({ children }) => (
  <div className="note-container">{children}</div>
)
