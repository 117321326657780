import React from "react"
import PropTypes from "prop-types"

export const Card = ({ children, additionalClasses }) => (
  <div className={`card${additionalClasses ? ` ${additionalClasses}` : ""}`}>
    {children}
  </div>
)

Card.defaultProps = {
  additionalClasses: "",
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
}

export const CardSection = ({ children, desktopColumns }) => (
  <section
    className={`card-section${
      desktopColumns > 1 ? ` card-section-col-${desktopColumns}` : ""
    }`}
  >
    {children}
  </section>
)

CardSection.defaultProps = {
  desktopColumns: 2,
}

CardSection.propTypes = {
  children: PropTypes.node.isRequired,
  desktopColumns: PropTypes.number,
}
