import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const InputPassword = ({ label, name }) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const [field, meta] = useField(name)

  return (
    <div className={`input${meta.touched && meta.error ? " form-error" : ""}`}>
      <Label name={name}>{label}</Label>
      <div
        className={`input-password${
          passwordVisible ? " password-visible" : ""
        }`}
      >
        <input
          className="input-field"
          type={passwordVisible ? "text" : "password"}
          name={name}
          id={name}
          {...field}
        />
        <button
          aria-label={passwordVisible ? "Hide password" : "View password"}
          className="input-password-button"
          onClick={() =>
            setPasswordVisible((passwordVisible) => !passwordVisible)
          }
          type="button"
        >
          <span
            className={passwordVisible ? "icon-eye-hide" : "icon-eye-show"}
          ></span>
        </button>
      </div>
      <span className="form-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
