import React from "react"
import { Line, defaults } from "react-chartjs-2"
import Moment from "moment"
import axios from "axios"
import DatePicker from "react-date-picker"
import { ReactSVG } from "react-svg"

import { Card, CardSection, DataDisplay } from "../../components"
import { useAuth } from "../../hooks"
import { formatChartData } from "./formatChartData"

import CalendarIcon from "../../images/calendar.svg"
import CalendarChevron from "../../images/date-picker-chevron.svg"
import LoaderSVG from "../../images/loader.svg"

export const Dashboard = () => {
  const auth = useAuth()
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })

  const [dateRange, setDateRange] = React.useState({
    startDate: Moment(new Date()).subtract(6, "months").toDate(),
    endDate: new Date(),
  })

  const [orders, setOrders] = React.useState({
    months: [],
    allOrders: {
      data: [],
      total: 0,
    },
    upfront: {
      data: [],
      total: 0,
    },
    halfUpfront: {
      data: [],
      total: 0,
    },
    finance: {
      data: [],
      total: 0,
    },
  })

  const [chartData, setChartData] = React.useState({})

  defaults.global.tooltips.backgroundColor = "rgba(0, 0, 0, 1)"
  defaults.global.defaultFontFamily = "'Noto sans', sans-serif"
  defaults.global.legend.align = "start"

  // Must be inside component as using this.baseChartData effectively
  const baseChartData = {
    labels: [],
    datasets: [
      {
        label: "All orders",
        data: [],
        fill: false,
        backgroundColor: "#ccc",
        borderColor: "#ccc",
      },
      {
        label: "Up-front",
        data: [],
        fill: false,
        backgroundColor: "#354FA2",
        borderColor: "#354FA2",
      },

      {
        label: "Finance",
        data: [],
        fill: false,
        backgroundColor: "#12996C",
        borderColor: "#12996C",
      },
      {
        label: "50% up-front",
        data: [],
        fill: false,
        backgroundColor: "#f5d03b",
        borderColor: "#f5d03b",
      },
    ],
  }

  const getChartData = async () => {
    setLoader({
      loading: true,
      error: false,
    })

    try {
      const chartData = await axios({
        method: "post",
        url: "/orders/stats/overview-chart-months",
        data: {
          startDate: Moment(dateRange.startDate).toISOString(),
          endDate: Moment(dateRange.endDate).toISOString(),
        },
      })
      setChartData(formatChartData(chartData.data, baseChartData))
      setOrders(chartData.data)
    } catch (error) {
      setLoader((loader) => ({
        ...loader,
        error: true,
      }))
    }

    setLoader((loader) => ({
      ...loader,
      loading: false,
    }))
  }

  React.useEffect(() => {
    getChartData()

    // return () => {
    //   setChartData(baseChartData)
    // }
    // eslint-disable-next-line
  }, [dateRange])

  return (
    <>
      <h1 className="heading-large">
        Welcome back{auth.user.displayName && ", " + auth.user.displayName}
      </h1>
      <div className="chart-dates">
        <DatePicker
          clearIcon={null}
          value={dateRange.startDate}
          onChange={(value) =>
            setDateRange((dateRange) => ({
              ...dateRange,
              startDate: value,
            }))
          }
          calendarIcon={<ReactSVG src={CalendarIcon} />}
          prevLabel={<ReactSVG src={CalendarChevron} />}
          nextLabel={<ReactSVG src={CalendarChevron} />}
        />
        <span className="chart-dates-divider">-</span>
        <DatePicker
          clearIcon={null}
          value={dateRange.endDate}
          onChange={(value) =>
            setDateRange((dateRange) => ({
              ...dateRange,
              endDate: value,
            }))
          }
          calendarIcon={<ReactSVG src={CalendarIcon} />}
          prevLabel={<ReactSVG src={CalendarChevron} />}
          nextLabel={<ReactSVG src={CalendarChevron} />}
        />
      </div>
      <section className="section-with-sidebar">
        <Card>
          <CardSection desktopColumns={1}>
            <div className="chart">
              <h2 className="heading-small">Activity</h2>
              <Line
                data={chartData}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            </div>
          </CardSection>
          <ReactSVG
            className={`chart-loader${!loader.loading ? " loaded" : ""}`}
            src={LoaderSVG}
          />
        </Card>
        <Card>
          <CardSection desktopColumns={1}>
            <DataDisplay label="Total orders" size="large">
              {orders.allOrders.total}
            </DataDisplay>
            <DataDisplay label="Up-front" size="large">
              {orders.upfront.total}
            </DataDisplay>
            <DataDisplay label="Finance" size="large">
              {orders.finance.total}
            </DataDisplay>
            <DataDisplay label="50% up-front" size="large">
              {orders.halfUpfront.total}
            </DataDisplay>
          </CardSection>
          <ReactSVG
            className={`chart-loader${!loader.loading ? " loaded" : ""}`}
            src={LoaderSVG}
          />
        </Card>
      </section>
    </>
  )
}
