import React from "react"
import { Router, LocationProvider, createHistory } from "@reach/router"
import SnackbarProvider from "react-simple-snackbar"

import {
  Login,
  Dashboard,
  Quotes,
  Orders,
  ViewOrder,
  ViewQuote,
} from "./containers"
import { Layout, Loader } from "./components"
import { ProvideAuth, useAuth } from "./hooks"
import "./style/main.scss"

const ProtectedApp = () => {
  const auth = useAuth()

  if (auth.user === null) {
    return <Loader text="Loading" error={false} />
  } else if (auth.user === false) {
    return <Login />
  } else {
    return (
      <Layout>
        <Router primary={false}>
          <Dashboard path="/" />
          <Orders path="/orders" />
          <ViewOrder path="/orders/:orderId" />
          <Quotes path="/quotes" />
          <ViewQuote path="/quotes/:quoteId" />
        </Router>
      </Layout>
    )
  }
}

const App = () => {
  let history = createHistory(window)

  return (
    <LocationProvider history={history}>
      <SnackbarProvider>
        <ProvideAuth>
          <ProtectedApp />
        </ProvideAuth>
      </SnackbarProvider>
    </LocationProvider>
  )
}

export default App
