import React from "react"

export const TableFilters = ({ children }) => (
  <div className="table-filters">{children}</div>
)

export const TableFiltersButton = ({ active, children, ...props }) => (
  <button
    className={`table-filters-button${active ? " active" : ""}`}
    {...props}
  >
    {children}
  </button>
)
