import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import Moment from "moment"
import { useNavigate } from "@reach/router"

import {
  BoilerSituation,
  SnackBarOptions,
  Table,
  TableBodyCell,
  TableBodyRow,
  TableFilters,
  TableFiltersButton,
  TableHeaderCell,
  TableHeaderRow,
  Tag,
  LoaderContainer,
  Loader,
} from "../../components"

export const Quotes = () => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [filters, setFilters] = React.useState({})
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })
  const [quotes, setQuotes] = React.useState({
    quotes: [],
    amount: 0,
  })
  const navigate = useNavigate()

  const getQuotes = async () => {
    setLoader({
      loading: true,
      error: false,
    })

    try {
      const quotes = await axios({
        method: "post",
        url: "/quotes",
        data: {
          filters,
        },
      })
      setQuotes(quotes.data)
    } catch (error) {
      console.log(error.response)
      setLoader((loader) => ({
        ...loader,
        error: true,
      }))
      if (error.response.data) {
        openSnackbar("Your account is not authorised")
      } else {
        openSnackbar("Could not get quotes, refresh to try again")
      }
    }
    setLoader((loader) => ({
      ...loader,
      loading: false,
    }))
  }

  React.useEffect(() => {
    getQuotes()
    // eslint-disable-next-line
  }, [filters])

  const navigateToQuote = (quoteId) => {
    navigate(`/quotes/${quoteId}`)
  }
  return (
    <>
      <h1 className="heading-large">
        Quotes{" "}
        {quotes && quotes.quotes.length > 0 && `(${quotes.quotes.length})`}
      </h1>
      <TableFilters>
        <TableFiltersButton
          active={filters.hasOrder === undefined}
          onClick={() => setFilters({})}
        >
          All
        </TableFiltersButton>
        <TableFiltersButton
          active={filters.hasOrder}
          onClick={() =>
            setFilters((filters) => ({ ...filters, hasOrder: true }))
          }
        >
          Order
        </TableFiltersButton>
        <TableFiltersButton
          active={filters.hasOrder === false}
          onClick={() =>
            setFilters((filters) => ({ ...filters, hasOrder: false }))
          }
        >
          No order
        </TableFiltersButton>
      </TableFilters>
      <LoaderContainer type="list-view">
        {loader.loading || loader.error ? (
          <Loader
            text="Getting quotes"
            error={loader.error}
            errorText="Could not get quotes, refresh to try again"
          />
        ) : (
          quotes &&
          quotes.quotes.length > 0 && (
            <Table>
              <thead>
                <TableHeaderRow>
                  <TableHeaderCell>Quote</TableHeaderCell>
                  <TableHeaderCell initialView="tablet">
                    Situation
                  </TableHeaderCell>
                  <TableHeaderCell initialView="tablet">
                    Min. power
                  </TableHeaderCell>
                  <TableHeaderCell>Created</TableHeaderCell>
                  <TableHeaderCell initialView="desktop">Type</TableHeaderCell>
                  <TableHeaderCell initialView="desktop">
                    Postcode
                  </TableHeaderCell>
                  <TableHeaderCell>Callback</TableHeaderCell>
                  <TableHeaderCell initialView="mobile-large">
                    Order
                  </TableHeaderCell>
                </TableHeaderRow>
              </thead>
              <tbody>
                {quotes.quotes.map((quote) => (
                  <TableBodyRow
                    tabIndex={0}
                    key={quote.id}
                    onClick={() => navigateToQuote(quote.id)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && navigateToQuote(quote.id)
                    }
                  >
                    <TableBodyCell type="index">
                      #{quote.quoteNumber}
                    </TableBodyCell>
                    <TableBodyCell initialView="tablet">
                      <BoilerSituation situation={quote.boilerSituation} />
                    </TableBodyCell>
                    <TableBodyCell type="bold" initialView="tablet">
                      {quote.minKw !== 0 ? `${quote.minKw}` : "> 40"}kW
                    </TableBodyCell>
                    <TableBodyCell>
                      {Moment(quote.timestamp).format("DD/MM/YYYY")}
                    </TableBodyCell>
                    <TableBodyCell initialView="desktop">
                      {quote.combiConversion
                        ? "Combi Conversion"
                        : "Combi Swap"}
                    </TableBodyCell>
                    <TableBodyCell initialView="desktop">
                      {quote.postcode.toUpperCase()}
                    </TableBodyCell>
                    <TableBodyCell>
                      {quote.callbackRequestStatus ? (
                        quote.callbackRequestStatus === "created" ? (
                          <Tag type="alert">Not called</Tag>
                        ) : quote.callbackRequestStatus === "init" ? (
                          "No details provided"
                        ) : (
                          "Called"
                        )
                      ) : (
                        "-"
                      )}
                    </TableBodyCell>
                    <TableBodyCell type="boolean" initialView="mobile-large">
                      {quote.orderId && quote.orderNumber ? (
                        <span className="icon-tick-circle" />
                      ) : (
                        <span className="icon-cross-circle" />
                      )}
                    </TableBodyCell>
                  </TableBodyRow>
                ))}
              </tbody>
            </Table>
          )
        )}
      </LoaderContainer>
    </>
  )
}
