import React from "react"
import PropTypes from "prop-types"

export const DataDisplay = ({ children, label, size }) => (
  <div className={`data-display${size === "large" ? " large" : ""}`}>
    <label className="data-display-label">{label}</label>
    <div className="data-display-data">{children}</div>
  </div>
)

DataDisplay.defaultProps = {
  size: "medium",
}

DataDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["large", "medium"]),
}

export const DataDisplayPhoto = ({ url, description }) => (
  <img
    className="data-display-photo"
    src={url}
    alt={description}
    title={description}
  />
)

DataDisplayPhoto.propTypes = {
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
