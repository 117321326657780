import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import Moment from "moment"
import { Link } from "@reach/router"
import { Formik } from "formik"
import * as Yup from "yup"

import {
  ButtonLink,
  ButtonWithLoader,
  ButtonWithIconLink,
  Card,
  CardSection,
  Checkbox,
  DataDisplay,
  DataDisplayPhoto,
  Form,
  Loader,
  LoaderContainer,
  Note,
  NoteContainer,
  SnackBarOptions,
  TextArea,
} from "../../../components"
import { defaultRequiredText } from "../../../constants"
import { formatPaymentType } from "../../../utilities"

export const ViewOrder = ({ orderId }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [order, setOrder] = React.useState({})
  const [boilerInstalled, setBoilerInstalled] = React.useState()
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })

  const getOrder = async () => {
    setLoader({
      loading: true,
      error: false,
    })

    try {
      const order = await axios({
        method: "get",
        url: `/order/${orderId}`,
      })
      setOrder(order.data)
      setBoilerInstalled(order.data.installed)
    } catch (error) {
      console.log(error.response)
      setLoader((loader) => ({
        ...loader,
        error: true,
      }))
      if (error.response.data === "Unauthorised") {
        openSnackbar("Your account is not authorised")
      } else {
        openSnackbar("Could not get order, refresh to try again")
      }
    }
    setLoader((loader) => ({
      ...loader,
      loading: false,
    }))
  }

  React.useEffect(() => {
    getOrder()
    // eslint-disable-next-line
  }, [])

  const updateBoilerInstalled = async (boilerInstalled) => {
    setBoilerInstalled(boilerInstalled)
    try {
      await axios({
        method: "post",
        url: "/order/installed",
        data: {
          orderId: orderId,
          installed: boilerInstalled,
        },
      })

      openSnackbar("Boiler install status updated")
    } catch (error) {
      openSnackbar("Could not update boiler install status")
    }
  }

  const addNote = async (note, setSubmitting, resetForm) => {
    try {
      const newNote = await axios({
        method: "post",
        url: "/order/add-note",
        data: {
          orderId: orderId,
          note: note,
        },
      })

      setOrder(newNote.data)
      resetForm({})
    } catch (error) {
      openSnackbar("Could not add note, please try again")
    }
    setSubmitting(false)
  }

  return (
    <>
      <LoaderContainer type="single-view">
        {loader.loading || loader.error ? (
          <Loader
            text="Getting order"
            error={loader.error}
            errorText="Could not get order, refresh to try again"
          />
        ) : (
          <>
            <ButtonLink to="/orders">Orders</ButtonLink>
            <header className="layout-screen-header">
              <h1 className="heading-large heading-large-blue">
                #{order.orderNumber}
              </h1>
              <span>
                Placed: {Moment(order.paymentCompleteTimestamp).format("LLLL")}
              </span>
            </header>
            <section className="section-with-sidebar">
              <Card>
                <CardSection>
                  {order.boiler && (
                    <DataDisplay label="Boiler">
                      <a
                        className="external-link"
                        href={`https://repairsandservicing.co.uk/boilers/${order.boiler.slug}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="icon-external-link" />
                        <span className="external-link-text">
                          {order.boiler.name}
                        </span>
                      </a>
                    </DataDisplay>
                  )}
                  {order.boilerControl && (
                    <DataDisplay label="Controls">
                      {order.boilerControl.name}
                    </DataDisplay>
                  )}
                  <DataDisplay label="Install date">
                    {Moment(order.installDate).format("DD/MM/YYYY")}
                  </DataDisplay>
                  <div></div>
                  <Checkbox
                    label="Boiler installed"
                    name="installed"
                    value={boilerInstalled}
                    onChange={() => updateBoilerInstalled(!boilerInstalled)}
                  />
                </CardSection>
                <CardSection>
                  <DataDisplay label="Quote">
                    {order.quote && (
                      <Link to={`/quotes/${order.quote.id}`}>
                        #{order.quote.quoteNumber}
                      </Link>
                    )}
                  </DataDisplay>
                </CardSection>
                <CardSection>
                  <DataDisplay label="Photos">
                    {order.images && order.images.length > 0
                      ? order.images.map((image) => (
                          <DataDisplayPhoto
                            key={image}
                            url={image}
                            description="Customer uploaded photo"
                          />
                        ))
                      : "No photos uploaded"}
                  </DataDisplay>
                </CardSection>
                <CardSection desktopColumns={1}>
                  <DataDisplay label="Payment">
                    {order.paymentDetails &&
                      formatPaymentType(order.paymentDetails)}

                    <div className="data-display-data-buttons">
                      {order.paymentDetails && order.paymentDetails.stripeUrl && (
                        <ButtonWithIconLink
                          icon="external-link"
                          type="primary"
                          href={order.paymentDetails.stripeUrl}
                          external={true}
                        >
                          Open in Stripe
                        </ButtonWithIconLink>
                      )}
                      {order.docusignDetails &&
                        order.docusignDetails.envelopeUrl && (
                          <a
                            className="button-secondary"
                            href={order.docusignDetails.envelopeUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View contract
                          </a>
                        )}
                    </div>
                  </DataDisplay>
                </CardSection>
                <CardSection>
                  {order.notes && order.notes.length > 0 && (
                    <NoteContainer>
                      {order.notes.map((note) => (
                        <Note
                          key={note.timestamp}
                          note={note.note}
                          user={note.userDisplayName}
                          postDate={note.timestamp}
                        />
                      ))}
                    </NoteContainer>
                  )}
                  <Formik
                    initialValues={{
                      note: "",
                    }}
                    validationSchema={Yup.object().shape({
                      note: Yup.string().required(defaultRequiredText),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) =>
                      addNote(values.note, setSubmitting, resetForm)
                    }
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <TextArea name="note" label="New note"></TextArea>
                        <ButtonWithLoader type="submit" disabled={isSubmitting}>
                          Add note
                        </ButtonWithLoader>
                      </Form>
                    )}
                  </Formik>
                </CardSection>
              </Card>
              <div>
                <Card>
                  <CardSection desktopColumns={1}>
                    {order.userDetails && (
                      <>
                        <DataDisplay label="Customer">
                          {order.userDetails.firstName}{" "}
                          {order.userDetails.lastName}
                        </DataDisplay>
                        <DataDisplay label="Email">
                          {order.userDetails.email}
                        </DataDisplay>
                        <DataDisplay label="Phone(s)">
                          {order.userDetails.mobile}
                          {order.userDetails.homePhone && (
                            <>
                              <br />
                              {order.userDetails.homePhone}
                            </>
                          )}
                        </DataDisplay>
                        {order.userDetails.address && (
                          <>
                            <DataDisplay label="Address">
                              {order.userDetails.address.line1},<br />
                              {order.userDetails.address.line2 && (
                                <>
                                  {order.userDetails.address.line2},<br />
                                </>
                              )}
                              {order.userDetails.address.city},<br />
                              {order.userDetails.address.postalCode}
                            </DataDisplay>
                            {order.userDetails.address.line1 &&
                              order.userDetails.address.city &&
                              order.userDetails.address.postalCode && (
                                <a
                                  className="link-blue"
                                  href={`https://maps.google.com/?q=${order.userDetails.address.line1}, ${order.userDetails.address.city}, ${order.userDetails.address.postalCode}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View in Google Maps
                                </a>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </CardSection>
                </Card>
              </div>
            </section>
          </>
        )}
      </LoaderContainer>
    </>
  )
}
