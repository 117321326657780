import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useSnackbar } from "react-simple-snackbar"
import { ReactSVG } from "react-svg"

import { defaultRequiredText } from "../../constants"
import {
  Form,
  Input,
  InputPassword,
  ButtonWithLoader,
  SnackBarOptions,
} from "../../components"
import { useAuth } from "../../hooks"

import HARSLogo from "../../images/Home-Assist-Logo.svg"

export const Login = () => {
  const auth = useAuth()
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const onSubmit = async (values, setSubmitting) => {
    try {
      await auth.signIn(values.email, values.password, () =>
        openSnackbar("Incorrect log in details, please try again")
      )
      setSubmitting(false)
    } catch (error) {
      openSnackbar("Something went wrong, please try again")
      setSubmitting(false)
    }
  }

  return (
    <main className="login">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email address")
            .required(defaultRequiredText),
          password: Yup.string().required(defaultRequiredText),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ReactSVG src={HARSLogo} />
            <Input label="Email" name="email" />
            <InputPassword label="Password" name="password" />
            <ButtonWithLoader
              additionalClasses="button-submit"
              type="submit"
              disabled={isSubmitting}
            >
              Log in
            </ButtonWithLoader>
          </Form>
        )}
      </Formik>
    </main>
  )
}
