import React from "react"
import axios from "axios"
import Moment from "moment"
import { useSnackbar } from "react-simple-snackbar"
import { Link } from "@reach/router"
import { Formik } from "formik"
import * as Yup from "yup"

import {
  BoilerSituation,
  ButtonLink,
  ButtonWithIconLink,
  ButtonWithLoader,
  Card,
  CardSection,
  Checkbox,
  DataDisplay,
  DataDisplayPhoto,
  Form,
  LineItems,
  Loader,
  LoaderContainer,
  Note,
  NoteContainer,
  SnackBarOptions,
  Tag,
  TextArea,
} from "../../../components"
import { defaultRequiredText } from "../../../constants"

export const ViewQuote = ({ quoteId }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [quote, setQuote] = React.useState({})
  const [callback, setCallback] = React.useState(null)
  const [order, setOrder] = React.useState(null)
  const [callbackCompleted, setCallbackCompleted] = React.useState()
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })

  React.useEffect(() => {
    const getQuote = async () => {
      setLoader({
        loading: true,
        error: false,
      })

      try {
        const quote = await axios({
          method: "get",
          url: `quote/admin/${quoteId}`,
        })
        setCallback(quote.data.callbackRequest)
        setQuote(quote.data.quote)
        setOrder(quote.data.order)
        setCallbackCompleted(
          quote.data.quote.callbackRequestStatus === "contact-attempted" ||
            quote.data.quote.callbackRequestStatus === "contacted"
        )
        setLoader((loader) => ({
          ...loader,
          loading: false,
        }))
      } catch (error) {
        console.log(error.response)
        setLoader((loader) => ({
          ...loader,
          error: true,
          loading: false,
        }))
        if (error.response.data) {
          openSnackbar("Your account is not authorised")
        } else {
          openSnackbar("Could not get quote, refresh to try again")
        }
      }
    }

    getQuote()
    // eslint-disable-next-line
  }, [quoteId])

  const addNote = async (note, setSubmitting, resetForm) => {
    try {
      const newNote = await axios({
        method: "post",
        url: "/quote/add-note",
        data: {
          quoteId: quoteId,
          note: note,
        },
      })

      setQuote(newNote.data)
      resetForm({})
    } catch (error) {
      openSnackbar("Could not add note, please try again")
    }
    setSubmitting(false)
  }

  const updateCallbackCalled = async (callbackCompleted) => {
    setCallbackCompleted(callbackCompleted)
    try {
      await axios({
        method: "post",
        url: "/quote/callback-request/status",
        data: {
          quoteId: quoteId,
          status: callbackCompleted ? "contacted" : "created",
        },
      })

      openSnackbar("Callback completed status updated")
    } catch (error) {
      console.log(error.response)
      openSnackbar("Could not update callback completed status")
    }
  }

  return (
    <>
      <LoaderContainer type="single-view">
        {loader.loading || loader.error ? (
          <Loader
            text="Getting quote"
            error={loader.error}
            errorText="Could not get quote, refresh to try again"
          />
        ) : (
          <>
            <ButtonLink to="/quotes">Quotes</ButtonLink>
            <header className="layout-screen-header">
              <h1 className="heading-large heading-large-blue">
                #{quote.quoteNumber}
              </h1>
              <span>Created: {Moment(quote.timestamp).format("LLLL")}</span>
            </header>
            <section className="section-with-sidebar">
              <Card>
                <CardSection desktopColumns={1}>
                  <DataDisplay label="Min. power">{quote.minKw}Kw</DataDisplay>
                  <div>
                    <ButtonWithIconLink
                      icon="external-link"
                      type="primary"
                      href={`https://repairsandservicing.co.uk/quote/result?quote=${quoteId}`}
                      external={true}
                    >
                      View suggested boilers
                    </ButtonWithIconLink>
                  </div>
                </CardSection>
                <CardSection>
                  <DataDisplay label="Situation">
                    {quote.boilerSituation ? (
                      <BoilerSituation situation={quote.boilerSituation} />
                    ) : (
                      "Could not find boiler situation"
                    )}
                  </DataDisplay>
                  <DataDisplay label="Moving boiler">
                    {quote.boilerMove ? "Yes" : "No"}
                  </DataDisplay>
                  <DataDisplay label="Current boiler type">
                    {quote.currentBoilerType}
                  </DataDisplay>
                  <DataDisplay label="Converting to combi">
                    {quote.currentBoilerType === "Combi"
                      ? "-"
                      : quote.combiConversion
                      ? "Yes"
                      : "No"}
                  </DataDisplay>
                  <DataDisplay label="Flue location">
                    {quote.boilerFlueExit}
                  </DataDisplay>
                  <DataDisplay label="Fuel Type">{quote.fuelType}</DataDisplay>
                  <DataDisplay label="Property type">
                    {quote.propertyType}
                  </DataDisplay>
                  <DataDisplay label="Bedrooms">{quote.bedrooms}</DataDisplay>
                  <DataDisplay label="Bathtubs">{quote.bathtubs}</DataDisplay>
                  <DataDisplay label="Separate showers">
                    {quote.showers}
                  </DataDisplay>
                  <DataDisplay label="Radiators">{quote.radiators}</DataDisplay>
                </CardSection>
                <LineItems
                  setQuote={setQuote}
                  quoteId={quoteId}
                  items={quote.lineItems}
                />
                <CardSection>
                  {quote.notes && quote.notes.length > 0 && (
                    <NoteContainer>
                      {quote.notes.map((note) => (
                        <Note
                          key={note.timestamp}
                          note={note.note}
                          user={note.userDisplayName}
                          postDate={note.timestamp}
                        />
                      ))}
                    </NoteContainer>
                  )}
                  <Formik
                    initialValues={{
                      note: "",
                    }}
                    validationSchema={Yup.object().shape({
                      note: Yup.string().required(defaultRequiredText),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) =>
                      addNote(values.note, setSubmitting, resetForm)
                    }
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <TextArea name="note" label="New note"></TextArea>
                        <ButtonWithLoader type="submit" disabled={isSubmitting}>
                          Add note
                        </ButtonWithLoader>
                      </Form>
                    )}
                  </Formik>
                </CardSection>
              </Card>
              <div>
                <Card>
                  {callback && (
                    <>
                      <CardSection desktopColumns={1}>
                        <Tag type="alert">Callback</Tag>
                        <Checkbox
                          label="Callback completed"
                          name="callbackCompleted"
                          value={callbackCompleted}
                          onChange={() =>
                            updateCallbackCalled(!callbackCompleted)
                          }
                        />
                      </CardSection>
                      <CardSection desktopColumns={1}>
                        <DataDisplay label="Contact">
                          {callback.name && callback.name}
                          <br />
                          {callback.email && callback.email}
                          <br />
                          {callback.mobile && callback.mobile}
                          <br />
                          {callback.homePhone && callback.homePhone}
                        </DataDisplay>
                        {callback.goodTimeToCall && (
                          <DataDisplay label="Is there a good time for us to call?">
                            {callback.goodTimeToCall}
                          </DataDisplay>
                        )}
                        {callback.images && callback.images.length > 0 && (
                          <DataDisplay label="Photos">
                            {callback.images.map((image) => (
                              <DataDisplayPhoto
                                key={image}
                                url={image}
                                description="Customer uploaded content"
                              />
                            ))}
                          </DataDisplay>
                        )}
                        {callback.notes && (
                          <DataDisplay label="Additional notes">
                            {callback.notes}
                          </DataDisplay>
                        )}
                      </CardSection>
                    </>
                  )}
                  {console.log(order)}
                  {quote.orderId &&
                  quote.orderNumber &&
                  order.status === "payment-completed" ? (
                    <CardSection>
                      <DataDisplay label="Order">
                        <Link to={`/orders/${quote.orderId}`}>
                          #{quote.orderNumber}
                        </Link>
                      </DataDisplay>
                    </CardSection>
                  ) : quote.moreInfoNeeded ? (
                    <CardSection desktopColumns={1}>
                      Callback required but no user details provided
                    </CardSection>
                  ) : (
                    <CardSection desktopColumns={1}>
                      No order created
                    </CardSection>
                  )}
                  <CardSection desktopColumns={1}>
                    <DataDisplay label="Postcode">{quote.postcode}</DataDisplay>
                  </CardSection>
                </Card>
              </div>
            </section>
          </>
        )}
      </LoaderContainer>
    </>
  )
}
