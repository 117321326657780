import React from "react"
import { Link } from "@reach/router"
import PropTypes from "prop-types"

export const ButtonLink = ({ to, children }) => (
  <Link className="button-link" to={to}>
    <span className="icon-chevron-left"></span>
    <span className="button-link-text">{children}</span>
  </Link>
)

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
