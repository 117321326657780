import React from "react"
import PropTypes from "prop-types"

import { Tag } from "../"

export const BoilerSituation = ({ situation }) => {
  if (situation === "broken") {
    return <Tag type="warning">Broken boiler</Tag>
  } else if (situation === "replace-soon") {
    return <Tag>Needing soon</Tag>
  } else {
    return <Tag>Just looking</Tag>
  }
}

BoilerSituation.propTypes = {
  situation: PropTypes.oneOf(["broken", "replace-soon", "just-looking"])
    .isRequired,
}
